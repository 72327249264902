<template>
    <v-row align="center" justify="center" no-gutters>
        <v-col cols="12">
            <v-hover v-slot:default="{ hover }">
                <v-row align="center" dense justify="center" no-gutters>
                    <v-col :class="getQuestionRowClass" class="question-side-border">
                        <v-row align="center" class="question" dense justify="start" no-gutters>
                            <v-btn class="drag-handle" icon>
                                <v-icon>mdi-drag</v-icon>
                            </v-btn>
                            <v-col cols="6" md="5" xl="6">
                                <v-textarea
                                    :placeholder="getQuestionTitlePlaceholder" :value="question.title"
                                    auto-grow autofocus clearable dense flat
                                    hide-details no-resize rows="1" solo
                                    @blur="updateFocusStateTrackerValue(false)"
                                    @focus="updateFocusStateTrackerValue(true)"
                                    @input="updateQuestionField('title', $event ? $event : '', indexes)"/>
                            </v-col>
                            <v-col v-if="isQuestionTypeSection(question)">
                                <v-row align="center" justify="end" no-gutters>
                                    <v-col>
                                        <v-row align="center" justify="end" no-gutters>
                                            <v-checkbox :false-value="false"
                                                        :input-value="question.options.repeatSection" :true-value="true"
                                                        class="ms-5 my-auto" hide-details
                                                        label="Repeat section"
                                                        @change="updateQuestionOptionField('repeatSection', !!$event)"/>
                                            <v-tooltip max-width="350px" top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" icon>
                                                        <v-icon>mdi-information-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>The section title should describe what the check in this section refer to, e.g. 'person' or 'device'.</span>
                                            </v-tooltip>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-text-field
                                            :min="0" :value="question.options.weight" autofocus class="ms-5"
                                            dense hide-details label="Weight" outlined type="number"
                                            @change="updateQuestionOptionField('weight', $event)"/>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="isQuestionTypeCheck(question)">
                                <v-row align="center" justify="end" no-gutters>
                                    <v-menu v-model="showResponseListMenu" :close-on-content-click="false"
                                            :nudge-width="50" left offset-y transition="slide-x-transition">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" class="grey lighten-5"
                                                   elevation="0" large link tile>
                                                <kurcc-question-response
                                                    :disable-hover-effect="true"
                                                    :response="question.options.response"/>
                                            </v-btn>
                                        </template>
                                        <v-row dense no-gutters>
                                            <v-col cols="12">
                                                <v-card flat>
                                                    <v-card-title>Type of responses</v-card-title>
                                                    <kurcc-question-response-list
                                                        :show-parent-menu.sync="showResponseListMenu"
                                                        @update-response="updateQuestionOptions($event)"/>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-menu>
                                    <v-checkbox v-if="!isQuestionResponseCheck && !isQuestionResponseNoResponse"
                                                :false-value="false" :input-value="answerRequired" :true-value="true"
                                                class="ms-5 my-auto checkbox-height" hide-detail
                                                @change="updateQuestionOptionField('answerRequired', !!$event)"/>
                                    <v-menu v-if="showDropDown" :close-on-content-click="false" offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" icon>
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-card v-if="isQuestionResponseMultiChoice" class="px-3 py-1 white">
                                            <v-switch v-model="question.options.multiSelect"
                                                      class="my-3" hide-details label="Multiple Selection"
                                                      @change="updateQuestionOptionField('multiSelect', !!$event)">
                                            </v-switch>
                                            <v-switch v-model="question.options.textComment"
                                                      class="my-3" hide-details label="Text Comment"
                                                      @change="updateQuestionOptionField('textComment', !!$event)">
                                            </v-switch>
                                            <v-switch v-model="question.options.photoComment" class="my-3" hide-details
                                                      label="Photo Comment"
                                                      @change="updateQuestionOptionField('photoComment', !!$event)">
                                            </v-switch>
                                            <v-switch v-model="question.options.reportIssue"
                                                      class="my-3" hide-details label="Report Issue"
                                                      @change="updateQuestionOptionField('reportIssue', !!$event)">
                                            </v-switch>
                                        </v-card>

                                        <v-card v-if="isQuestionResponseDateTime" class="px-3 py-1 white">
                                            <v-switch v-model="question.options.showDate" class="my-3" hide-details
                                                      label="Date"
                                                      @change="updateQuestionOptionField('showDate', !!$event)"></v-switch>
                                            <v-switch v-model="question.options.showTime" class="my-3" hide-details
                                                      label="Time"
                                                      @change="updateQuestionOptionField('showTime', !!$event)"></v-switch>
                                        </v-card>
                                    </v-menu>
                                </v-row>
                            </v-col>
                        </v-row>
                        <kurcc-question-page-action-bar :indexes="indexes" :is-child="isChild"/>
                    </v-col>
                    <v-col class="py-0" cols="auto">
                        <kurcc-form-spacer class="pa-0">
                            <v-container v-if="hover">
                                <v-btn icon @click="deleteQuestion">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn icon @click="cloneQuestionAfter">
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                            </v-container>
                        </kurcc-form-spacer>
                    </v-col>
                </v-row>
            </v-hover>
        </v-col>
        <v-col cols="12">
            <slot/>
        </v-col>
    </v-row>
</template>

<script>
import {
    isQuestionTypeSection,
    isQuestionTypeCheck,
    updateQuestionField,
    updateQuestionOptionField
} from '@/modules/forms/helpers/helpers';
import {
    CLONE_INSPECTION_FORM_QUESTION,
    DELETE_INSPECTION_FORM_QUESTION,
} from "@/modules/app/store/mutation-types";
import {ResponseType} from "@/modules/forms/enums/response-type";
import QuestionOptions from "@/modules/forms/models/question-options";
import {questionOptions} from "@/modules/forms/constants/pre-defined-question-options";

export default {
    name: "KurccQuestion",
    components: {
        KurccQuestionPageActionBar: () => import('@/modules/forms/components/KurccQuestionPageActionBar'),
        KurccFormSpacer: () => import('@/modules/forms/components/KurccFormSpacer'),
        KurccQuestionResponse: () => import('@/modules/forms/components/question/KurccQuestionResponse'),
        KurccQuestionResponseList: () => import('@/modules/forms/components/question/KurccQuestionResponseList')
    },
    props: {
        question: {
            type: Object,
            required: true
        },
        level: {
            type: Number,
            required: false,
            default: 0
        },
        questionIndex: {
            type: Number,
            required: false
        },
        parentIndex: {
            type: Number,
            required: false
        },
        grandparentIndex: {
            type: Number,
            required: false
        },
        pageIndex: {
            type: Number,
            required: true
        },
        showBottomBorder: {
            type: Boolean,
            default: false,
            required: true,
        },
        showTopBorder: {
            type: Boolean,
            default: false,
            required: true,
        }
    },
    data() {
        return {
            textHasFocus: false,
            isQuestionTypeSection,
            isQuestionTypeCheck,
            updateQuestionField,
            updateQuestionOptionFieldByIndex: updateQuestionOptionField,
            showResponseListMenu: false,
            multiChoiceOptions: {
                multiSelect: false,
                textComment: true,
                photoComment: true,
                reportIssue: true,
            }
        }
    },
    computed: {
        isChild() {
            return this.level > 0
        },
        getQuestionRowClass() {
            return {
                'question-border-top': this.showTopBorder,
                'question-border-bottom': this.showBottomBorder,
                'question-focus-color': this.textHasFocus,
            }
        },
        getQuestionTitlePlaceholder() {
            return this.isQuestionTypeCheck(this.question) ? 'Enter Check' : 'Enter Section Name'
        },
        isQuestionResponseMultiChoice() {
            return this.isQuestionTypeCheck(this.question) &&
                (this.question.options.response.type === ResponseType["Multiple choice"] ||
                    this.question.options.response.type === ResponseType.Dropdown)
        },
        isQuestionResponseCheck() {
            return this.question.options.response.type === ResponseType.Checkbox
        },
        isQuestionResponseNoResponse() {
            return this.question.options.response.type === ResponseType["No response required"]
        },
        isQuestionResponseDateTime() {
            return this.isQuestionTypeCheck(this.question) && this.question.options.response.type === ResponseType["Date & Time"]
        },
        showDropDown() {
            return this.isQuestionResponseMultiChoice || this.isQuestionResponseDateTime
        },
        indexes() {
            return {
                pageIndex: this.pageIndex,
                grandparentIndex: this.grandparentIndex,
                parentIndex: this.parentIndex,
                questionIndex: this.questionIndex,
            }
        },
        answerRequired() {
            return this.question.options.answerRequired
        }
    },
    methods: {
        updateFocusStateTrackerValue(value) {
            this.textHasFocus = value
        },
        updateQuestionOptionField(field, value) {
            this.updateQuestionOptionFieldByIndex(field, value, this.indexes)
        },
        updateQuestionOption(option) {
            this.updateQuestionField('options', new QuestionOptions(option).getOptions(), this.indexes)
        },
        updateQuestionOptions(response) {
            this.clearQuestionAnswer()

            switch (response.type) {
                case ResponseType["Multiple choice"]:
                    this.updateQuestionOption(questionOptions.check.multiChoice)
                    break
                case ResponseType.Text:
                    this.updateQuestionOption(questionOptions.check.text)
                    break
                case ResponseType.Number:
                    this.updateQuestionOption(questionOptions.check.number)
                    break
                case ResponseType.Checkbox:
                    this.updateQuestionOption(questionOptions.check.check)
                    break
                case ResponseType.Annotation:
                    this.updateQuestionOption(questionOptions.check.annotation)
                    break
                case ResponseType.Photo:
                    this.updateQuestionOption(questionOptions.check.photo)
                    break
                case ResponseType.Signature:
                    this.updateQuestionOption(questionOptions.check.signature)
                    break
                case ResponseType["Date & Time"]:
                    this.updateQuestionOption(questionOptions.check.datetime)
                    break
                case ResponseType.Dropdown:
                    this.updateQuestionOption(questionOptions.check.dropdown)
                    break
                case ResponseType["No response required"]:
                    this.updateQuestionOption(questionOptions.check.noResponse)
                    break
            }
        },
        cloneQuestionAfter() {
            let insertIndex = this.questionIndex + 1 // insert after current item index
            this.$store.commit(CLONE_INSPECTION_FORM_QUESTION, {
                pageIndex: this.pageIndex,
                insertIndex,
                question: this.question,
                parentIndex: this.parentIndex,
                grandparentIndex: this.grandparentIndex,
            })
        },
        deleteQuestion() {
            this.$store.commit(DELETE_INSPECTION_FORM_QUESTION, {
                pageIndex: this.pageIndex,
                questionIndex: this.questionIndex,
                parentIndex: this.parentIndex,
                grandparentIndex: this.grandparentIndex,
            })
        },
        clearQuestionAnswer() {
            this.updateQuestionField('answer', null, this.indexes)
        }
    }
}
</script>

<style lang="scss" scoped>
.checkbox-height {
    height: 24px;
}

.question {
    min-height: 44px;
}

$question-border: thin solid rgba(0, 0, 0, 0.12);

.question-side-border {
    padding: 9px;
    border-right: $question-border;
    border-left: $question-border;
}

.question-border-top {
    border-top: $question-border;
}

.question-border-bottom {
    border-bottom: $question-border;
}

.question-focus-color {
    background-color: #f5f5f5;
}

.check-width {
    width: calc(90% - 40px) !important;
    margin-left: calc(5% + 40px) !important;
}
</style>
